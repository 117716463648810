import React, { useRef, useState } from "react"
import ReCAPTCHA from 'react-google-recaptcha'
import Head from "../../components/global/head"
import Form from "./form"
import ContentBoundary from "../../components/content/boundary"
import HeroIntroBanner from "../../components/modules/hero_intro_banner"

import { states } from "../../__mocks__/data"
import "./_temp_styles.scss"

const Contact = () => {
  const [isPackChecked, setIsPackChecked] = useState(false)
  const [addressStateValue, setAddressStateValue] = useState("")
  const [state, setState] = useState({})
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handlePackToggle = (e) => {
    setIsPackChecked(!isPackChecked)
    setState({
      ...state,
      [e.target.name]: e.target.checked,
    })
  }

  const handleLocationState = (e) => {
    setAddressStateValue(e.target.value)
    setState({
      ...state,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    const recaptchaValue = recaptchaRef.current?.getValue();

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        "g-recaptcha-response": recaptchaValue,
        ...state,
      }),
    })
      .then(() => {
        location.href = "/contact/thanks"
      })
      .catch((error) => alert(error))
  }

  return (
    <>
      <Head
        title="Contact Us"
        url="https://www.choosetap.com.au/contact"
        description="We’d love to hear from you, so drop us a line! Someone from our team will get back to you as quickly as we can."
        keywords="choose tap contact, contact choose tap, choose tap email, choose tap refiller"
        bodyClassname="body__contact"
      />
      <section id="contact-form" className="pages__contact">
        <HeroIntroBanner
          title={`Drop us a line`}
          sectionName={`Contact us`}
          themeColor="grey"
          shortDescription="We’d love to hear from you, and will get back to your message as quickly as we can!"
          isSubmissionConfirmInstance={true}
        />
        <main>
          <ContentBoundary>
            <div className="contact">
              <Form
                addressStateValue={addressStateValue}
                formSubmit={handleSubmit}
                handleChange={handleChange}
                handleLocationState={handleLocationState}
                handlePackToggle={handlePackToggle}
                isPackChecked={isPackChecked}
                states={states}
                recaptchaRef={recaptchaRef}
              />
            </div>
          </ContentBoundary>
        </main>
      </section>
    </>
  )
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default Contact
